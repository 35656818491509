// TYPOGRAPHY
@font-face {
	font-family: 'balto';
	src: url('../assets/fonts/Balto-Medium.woff2') format('woff2');
}

@font-face {
	font-family: 'harriet';
	src: url('../assets/fonts/Harriet-Display-Italic.woff2') format('woff2');
}

$primary-font-family: 'Helvetica', 'Arial', sans-serif;
$primary-bold-font-family: balto, serif;

$secondary-font-family: harriet, serif;

$standard-line-height: 1.7pc;
$standard-border-radius: 8px;

// THEME
$primary-color: #232420;
$secondary-color: #c9f2f1;
$light-color: #7c7c7c;
$black-color: black !default;
$white-color: white;

// RESPONSIVE
$mobile-width: 640px;
$tablet-width: 768px;
$small-desktop-width: 1024px;
$desktop-width: 1280px;
$large-desktop-width: 1536px;

$large-desktop-padding: 15%;
$desktop-padding: 100px;
$tablet-padding: 20px;
$mobile-padding: 10px;
