@import 'src/styles/variables';

@keyframes loader {
	0% {
		transform: rotate(0deg);
	}
	100% {
		transform: rotate(360deg);
	}
}

.spinner {
	border-radius: 50%;
	position: relative !important;
	height: 50px;
	width: 50px;
}
.spinner:before {
	content: '';
	z-index: 10;
	opacity: 1;
	background-color: white;
	border-radius: 50%;
	position: absolute;
	width: 34px;
	height: 34px;
	top: 8px;
	left: 8px;
	right: 0px;
}
.spinner span {
	display: inline-block;
	height: 100%;
	width: 100%;
	border-radius: 50%;
	background: linear-gradient(
		90deg,
		rgba(2, 0, 36, 1) 0%,
		rgba(217, 255, 0, 1) 0%,
		rgba(241, 0, 255, 1) 63%,
		rgba(0, 212, 255, 1) 100%
	); // overflow: hidden;
	animation: loader 2s linear infinite;
	animation-duration: 0.6s;
}
