@import 'src/styles/_variables';

#pricing {
	.paddedContainer {
		padding-bottom: 100px;
		padding-top: 125px;
		p {
			color: $light-color;
		}
		text-align: center;
		.flex {
			display: flex;
			justify-content: center;
			flex-wrap: wrap;
			.basic,
			.premium {
				width: 100%;
				margin: 10px;
				border: dotted 1px $black-color;
				padding: 20px 0;
				h4 {
					margin-bottom: 10px;
				}
				@media only screen and (min-width: $desktop-width) {
					width: calc(30% - 20px);
					margin: 30px;
				}
				button {
					border: dotted 1px $black-color;
				}
			}
		}
		hr {
			margin-bottom: 30px;
		}
		a {
			font-family: $primary-font-family;
			color: $black-color;
			font-size: 20px;
			display: inline-block;
			margin-bottom: 10px;
		}
	}
}
