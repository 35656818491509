@import 'src/styles/_variables';

.existingDocsHeader {
	::-webkit-scrollbar {
		display: none;
	}

	a,
	.viewAll {
		font-family: $primary-bold-font-family;
	}
}
