@import 'src/styles/_variables';

.error {
	text-align: center;

	button {
		width: 100%;
		margin-left: auto;
		margin-right: auto;
		margin-top: 10px;
		height: 40px;
		font-weight: bold;
	}
	p {
		color: $light-color;
	}
	h5 {
		font-size: 28px;
	}

	.ReactModal__Content {
		display: flex;
		flex-direction: column;
		justify-content: space-between;
		padding: 50px 10px 40px !important;
	}

	@media only screen and (min-width: $tablet-width) {
		.ReactModal__Content {
			padding: 50px 30px 40px !important;
			max-width: 400px;
		}
	}
}
