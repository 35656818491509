@import 'src/styles/_variables';

.rubric {
	.ReactModal__Content {
		display: flex;
		flex-direction: column;
		justify-content: space-between;
		padding: 50px 10px 40px !important;
		width: 100%;
	}

	.colors {
		background: linear-gradient(
			90deg,
			rgba(2, 0, 36, 1) 0%,
			rgba(217, 255, 0, 1) 0%,
			rgba(241, 0, 255, 1) 63%,
			rgba(0, 212, 255, 1) 100%
		);
	}

	@media only screen and (min-width: $tablet-width) {
		.ReactModal__Content {
			width: 450px;
			padding: 30px 40px !important;
		}
	}
}
