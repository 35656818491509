@import 'src/styles/_variables';

@keyframes oscillate {
	0% {
		background-position: 0% 35%;
	}
	50% {
		background-position: 100% 66%;
	}
	100% {
		background-position: 0% 35%;
	}
}

.editSection,
#create {
	.select-tone-text {
		font-family: $primary-font-family;
		margin: 55px 0 35px;
		text-align: center;
		font-size: 25px;
		color: $black-color;
	}
	.tone-container {
		display: flex;
		flex-wrap: wrap;
		justify-content: flex-start;

		.tone {
			margin: 5px;
			text-transform: capitalize;
			font-size: 18px;
			border-radius: $standard-border-radius;
			border: dotted 1px $black-color;
			padding: 4px 10px;
			font-family: $primary-font-family;
			cursor: pointer;
			color: $light-color;
			&:hover,
			&.active {
				background-color: $secondary-color;
				border-style: solid;
				color: $black-color;
			}
		}
		@media only screen and (min-width: $mobile-width) {
			justify-content: space-between;

			.tone {
				margin: 0;
			}
		}
	}
	.helpBanner {
		background-color: $secondary-color;
		padding: 5px 10px;
		position: fixed;
		bottom: 20px;
		z-index: 1;
		width: 90%;
		left: 2.5%;
		text-align: center;
		font-family: $primary-font-family;
		border-radius: 5px;
		border: dotted 1px $primary-color;
		.close {
			float: right;
			color: $light-color;
			opacity: 0;
			transition: opacity 0.3s;
			font-size: 20px;
			position: relative;
			top: -4px;
			cursor: pointer;
			&:hover {
				color: $primary-color;
			}
		}
		&:hover {
			border-style: solid;
			.close {
				opacity: 1;
			}
		}
		@media only screen and (min-width: $mobile-width) {
			width: 640px;
			left: calc(50% - 330px);
			height: 18px;
		}
	}
	.headerContainer {
		#topicHeader {
			&::placeholder {
				color: lighten($light-color, 10%);
			}
		}
	}
	.notFound {
		text-align: center;
		margin-top: 45vh;
	}
	.abortButton {
		position: fixed;
		bottom: 15px;
		left: calc(50% - 100px);
		z-index: 1;
		width: 220px;
		font-size: 16px;
		border-radius: 20px;
	}

	.humanizing {
		pointer-events: none;
		opacity: 0.3;
	}
	.humanizingText {
		h6 {
			font-family: $secondary-font-family;
		}
		p {
			color: $light-color;
			font-size: 16px;
			margin-bottom: 0;
		}
		width: 260px;
		left: calc(50% - 130px);
		margin-top: 100px;
		position: absolute;
		text-align: center;
		background-color: $white-color;
		padding: 10px 25px;
		z-index: 1;
		box-shadow: 1px 1px 7px rgba(0, 0, 0, 0.5);
	}
}

#create {
	padding-bottom: 100px;
	.firstSentence {
		margin-top: 50px;
		font-family: $primary-font-family;
		font-size: 20px;
	}
}
