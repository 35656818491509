@import 'src/styles/_variables';

#post {
	.veryPaddedContainer {
		padding-top: 80px;
		z-index: -1;
		min-height: 500px;
		@media only screen and (min-width: $desktop-width) {
			position: relative;
			top: -52px;
			padding-top: 150px;
		}
	}
	.backContainer {
		padding-bottom: 0;
		position: relative;
		top: 20px;
		display: none;

		@media only screen and (min-width: $desktop-width) {
			top: 50px;
		}
	}
	.back {
		display: inline-block;
		font-size: 50px;
		transform: rotate(180deg);
		z-index: 100;
		&:active {
			color: $primary-color;
		}
	}
	.header {
		margin-top: 0px;
	}
	p {
		line-height: 3pc;
	}
}
