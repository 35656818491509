@import 'src/styles/_variables';

$circle_size: 400px;
$circle_size_mobile: 250px;
$small_circle_size: 300px;
$small_circle_size_mobile: 200px;

@keyframes spin {
	from {
		transform: rotate(0deg);
	}
	to {
		transform: rotate(360deg);
	}
}

#unknown {
	.rollingContainer {
		position: absolute;
		text-align: center;
		left: calc(50vw - ($circle_size_mobile / 2) - 5px);
		top: calc(50vh - ($circle_size_mobile / 2) - 5px);
		border: dotted 5px $primary-color;
		width: $circle_size_mobile;
		height: $circle_size_mobile;
		line-height: $circle_size_mobile;
		border-radius: 50%;
		animation-name: spin;
		animation-duration: 18000ms;
		animation-iteration-count: infinite;
		animation-timing-function: linear;
		@media only screen and (min-width: $desktop-width) {
			width: $circle_size;
			height: $circle_size;
			line-height: $circle_size;
			left: calc(50vw - ($circle_size / 2) - 5px);
			top: calc(50vh - ($circle_size / 2) - 5px);
		}
	}
	h2 {
		width: $small_circle_size_mobile;
		height: $small_circle_size_mobile;
		line-height: $small_circle_size_mobile;
		border-radius: 50%;
		text-align: center;
		position: absolute;
		left: calc(50vw - ($small_circle_size_mobile / 2));
		top: calc(50vh - ($small_circle_size_mobile / 2));
		z-index: 1;
		@media only screen and (min-width: $desktop-width) {
			width: $small_circle_size;
			height: $small_circle_size;
			line-height: $small_circle_size;
			left: calc(50vw - ($small_circle_size / 2));
			top: calc(50vh - ($small_circle_size / 2));
		}
	}
}
