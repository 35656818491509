@import 'src/styles/_variables';

#writerOptions {
	.humanizerContainer {
		width: 100%;
		margin-top: 40px;
		margin-bottom: 50px;
		p {
			font-size: 20px;
		}
		text-align: center;
		.humanizerOption {
			margin-top: 0;
			font-size: 20px;
			margin: 0 10px;
			&.selected {
				background-color: $secondary-color;
			}
		}
	}
	.toneTypeList {
		width: 100%;
		display: flex;
		flex-direction: row;
		flex-wrap: wrap;
		justify-content: space-between;
		overflow-x: scroll;
		flex-wrap: nowrap;
		&::-webkit-scrollbar {
			display: none;
		}

		.toneTypeButton {
			border-radius: 12px;
			padding: 4px 12px;
			margin: 0 5px;
			width: max-content;
			display: inline-block;
			font-size: 16px;
			white-space: nowrap;
			border: dotted 1px $primary-color;
			&:hover {
				border-style: solid;
			}
		}

		.toneTypeButton.selected {
			background-color: $secondary-color;
			border-style: solid;
			font-weight: 600;
		}
	}
	.toneTypeContainer {
		p {
			font-size: 20px;
		}
		text-align: center;
		margin-top: 40px;
		width: 100%;
	}

	.refsContainer {
		display: flex;
		flex-direction: row;
		align-items: center;
		justify-content: space-between;
		margin: 50px 0 0px;
		cursor: pointer;
		width: 100%;

		#refsLabel {
			width: 80%;
			margin: 0;
		}

		.refsCheckbox {
			border: 1px dotted $primary-color;
			border-radius: 8px;
			width: 30px;
			height: 30px;
			margin: 0;

			.disable {
				position: relative;
				-webkit-touch-callout: none;
				-webkit-user-select: none;
				-ms-user-select: none;
				user-select: none;
				top: -6px;
				left: -6px;
				padding: 0;
				margin: 0;
			}
		}
		.refsCheckbox.checked {
			background-color: $secondary-color;
			border-style: solid;
		}
	}

	.optionsContainer {
		height: 0;
		overflow: hidden;
		transition: height 0.3s ease-in-out;
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
		position: relative;

		.opaqueCover {
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
			background-color: rgba(255, 255, 255, 0.8);
			border-radius: 3px;
			z-index: 1;
			display: flex;
			align-items: center;
			justify-content: center;

			.premiumButton {
				width: 40%;
				margin-top: -20px;
				box-shadow: 1px 1px 7px rgba(0, 0, 0, 0.5);
				border: dotted 1px $primary-color;

				&:hover {
					background-color: $secondary-color;
					border-style: solid;
				}

				p {
					margin: 0;
					font-size: 20px;
					text-transform: capitalize;
				}
			}

			@media (max-width: 767px) {
				.premiumButton {
					width: 85%;
				}
			}
		}
	}

	.optionsContainer.show {
		overflow: visible;
		height: max-content;
	}

	.expandButton {
		margin-bottom: 30px;
		:hover {
			cursor: pointer;
			span {
				background-color: $secondary-color;
			}
		}
	}

	.slider {
		-webkit-appearance: none;
		width: 100%;
		height: 10px;
		border: dotted 1px $primary-color;
		outline: none;
		opacity: 0.7;
		-webkit-transition: 0.2s;
		transition: opacity 0.2s;
		border-radius: 5px;
		&:hover {
			background: $secondary-color;
			border-style: solid;
		}
		&::-webkit-slider-thumb {
			-webkit-appearance: none;
			appearance: none;
			width: 20px;
			height: 20px;
			background: $black-color;
			border-radius: 50%;
			cursor: pointer;
		}
	}

	.sliderValue {
		text-align: center;

		.value {
			display: inline-block;
			font-family: $primary-bold-font-family;
			border: dotted 1px $primary-color;
			margin-right: 10px;
			padding: 0px 5px;
			&::-webkit-outer-spin-button,
			&::-webkit-inner-spin-button {
				-webkit-appearance: none;
				margin: 0;
			}
		}
		.units {
			font-size: 30px;
			color: $light-color;
		}
	}
	.topMargin {
		margin-top: 40px;
	}
	.opacity {
		opacity: 0.5;
	}
}

.customWritingStyle {
	#custom-style-editor {
		.ProseMirror {
			padding: 10px;
			min-height: 200px;
		}
		.ProseMirror p {
			font-size: 15px;
		}
		.ProseMirror:focus {
			outline: none;
		}
		.ProseMirror p.is-editor-empty:first-child::before {
			color: $light-color;
			content: attr(data-placeholder);
			height: 0;
			pointer-events: none;
			line-height: normal;
		}
	}
	.ReactModal__Content {
		padding: 20px 10px 40px !important;
		min-height: 200px;
		width: 90%;
	}

	@media only screen and (min-width: $tablet-width) {
		.ReactModal__Content {
			width: 600px;
			padding: 20px 30px 40px !important;
		}
	}
}
