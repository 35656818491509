@import 'src/styles/_variables';

.suggest {
	.ReactModal__Content {
		display: flex;
		flex-direction: column;
		justify-content: space-between;
		padding: 50px 10px 40px !important;
		min-height: 220px;
	}

	@media only screen and (min-width: $tablet-width) {
		.ReactModal__Content {
			padding: 40px 30px 40px !important;
			width: 500px;
			min-height: 220px;
		}
	}
}
