@import 'src/styles/_variables';

#blog {
	padding-bottom: 100px;
	hr {
		margin: 0;
	}
	.disclaimer {
		margin: 10px 0 0;
		color: $light-color;
		text-align: center;
	}
	.pageHeader {
		text-align: center;
		margin-top: 35px;
	}
	.postPreview {
		color: $primary-color;
		text-decoration: none;
		display: block;
		p {
			line-height: $standard-line-height;
			color: $light-color;
		}
		img {
			width: 100%;
			object-fit: cover;
		}
		&.large {
			border-bottom: dotted 1px $primary-color;
			margin-bottom: 20px;
			p {
				font-size: 15px;
				line-height: 20px;
			}
			@media only screen and (min-width: $tablet-width) {
				border-bottom: none;
				margin-bottom: 0px;
				p {
					font-size: 20px;
					line-height: $standard-line-height;
				}
			}
			.header {
				margin-top: 20px;
				font-size: 30px;
				@media only screen and (min-width: $tablet-width) {
					font-size: 50px;
				}
			}
			img {
				height: 200px;
				@media only screen and (min-width: $tablet-width) {
					height: 480px;
				}
			}
		}
		&.medium {
			.header {
				margin-top: 10px;
				margin-bottom: -10px;
			}
			img {
				height: 200px;
			}
			p {
				font-size: 15px;
				line-height: 20px;
			}
		}
		&.small {
			display: flex;
			justify-content: space-between;
			flex-wrap: wrap;
			padding: 20px 0;
			border-bottom: dotted 1px $primary-color;
			.image {
				width: 100%;
				height: 200px;
				overflow: hidden;
				@media only screen and (min-width: $tablet-width) {
					height: 140px;
					width: 20%;
				}
			}
			.text {
				width: 100%;
				font-size: 15px;
				p {
					line-height: 20px;
				}
				@media only screen and (min-width: $tablet-width) {
					width: 78%;
					p {
						line-height: $standard-line-height;
					}
				}
			}
			.header {
				margin-top: 10px;
				font-size: 30px;
				line-height: 30px;
				@media only screen and (min-width: $tablet-width) {
					font-size: 28px;
					margin-top: 0px;
				}
			}
			img {
				height: 200px;
			}
			p {
				font-size: 15px;
				line-height: 25px;
			}
		}
	}
	.topSection {
		display: flex;
		justify-content: space-between;
		margin-top: 40px;
		flex-wrap: wrap;

		.section1 {
			width: 100%;
			@media only screen and (min-width: $tablet-width) {
				width: 62%;
			}
		}
		.section2 {
			width: 100%;
			border-left: none;
			@media only screen and (min-width: $tablet-width) {
				width: 35%;
				border-left: dotted $primary-color 1px;
			}
			.postPreview {
				padding-left: 0%;
				@media only screen and (min-width: $tablet-width) {
					padding-left: 8%;
				}
			}
			hr {
				margin-bottom: 20px;
			}
		}
	}
}
