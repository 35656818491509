@tailwind base;
@tailwind components;
@tailwind utilities;
@import 'src/styles/_variables';

$vertical-padding: 70px;

body {
	margin: 0;
	-moz-osx-font-smoothing: grayscale;
	-webkit-font-smoothing: antialiased;
	backface-visibility: hidden;
	-webkit-backface-visibility: hidden;
}

button,
input,
optgroup,
select,
textarea {
	line-height: 1.2;
}

::-moz-selection {
	background: $secondary-color;
}

::selection {
	background: $secondary-color;
}

.paddedContainer {
	padding: $vertical-padding $mobile-padding 0;
	@media only screen and (min-width: $mobile-width) {
		padding: $vertical-padding $tablet-padding 0;
	}

	@media only screen and (min-width: $tablet-width) {
		padding: $vertical-padding $desktop-padding 0;
	}
	@media only screen and (min-width: $large-desktop-width) {
		padding: $vertical-padding $large-desktop-padding 0;
	}
}

.veryPaddedContainer {
	padding: $vertical-padding $mobile-padding;
	@media only screen and (min-width: $mobile-width) {
		padding: $vertical-padding $tablet-padding;
	}

	@media only screen and (min-width: $tablet-width) {
		padding: $vertical-padding $desktop-padding;
	}

	@media only screen and (min-width: $desktop-width) {
		padding: $vertical-padding 20% 20px 20%;
	}
}

.linkContainer,
.donateIcon {
	position: fixed;
	z-index: 10;
	@media only screen and (min-width: $tablet-width) {
		display: block;
		visibility: visible;
	}
}

.linkContainer {
	right: 20px;
	bottom: 60px;
	height: 0px;
	display: flex;
	flex-direction: column;
	transition: height 0.5s, opacity 0.5s;
	justify-content: space-between;
	opacity: 1;

	&.hidden {
		opacity: 0;
	}
	a {
		color: $black-color;
		text-decoration: none;
	}

	.item {
		display: block;
		font-size: 30px;
		font-family: $primary-font-family;
		width: 40px;
		height: 40px;
		margin-bottom: -40px;
		background-color: $white-color;
		border-radius: 50%;
		border: dotted 1px $black-color;
		cursor: pointer;
		&:not(.showMore) {
			&:hover {
				background-color: $secondary-color;
			}
		}
		&:hover {
			border-style: solid;
		}
		.helpIcon {
			span {
				position: relative;
				left: 11px;
				top: 3px;
			}
		}
		.twitterIcon,
		.linkedInIcon {
			svg {
				position: relative;
				left: 10px;
				top: 2px;
			}
		}
	}
	.showMore {
		z-index: 15;
		svg {
			transform: rotate(90deg);
			position: relative;
			left: 13px;
			top: 4px;
		}
		&:hover {
			background-color: $secondary-color;
		}
	}
	&.open {
		height: 180px;

		.showMore {
			background-color: $primary-color;
			svg {
				transform: rotate(-90deg);
				position: relative;
				top: 6px;
				color: $white-color;
			}
			&:hover {
				background-color: $primary-color;
			}
		}
	}
}
.topicDivider {
	left: 0;
	top: 80px;
	display: block;

	@media only screen and (min-width: $mobile-width) {
		width: calc(100% + 2.5px);
		top: 58px;
	}
}
.dividingBorder {
	border-bottom: dotted 1px $primary-color;
}
.oscillating-bar {
	height: 3px;
	border-radius: 2px;
	background: linear-gradient(90deg, rgba(217, 255, 0, 1) 0%, rgba(241, 0, 255, 1) 63%, rgba(0, 212, 255, 1) 100%);
	background-size: 600% 600%;
	-webkit-animation: oscillate 2s ease infinite;
	-moz-animation: oscillate 2s ease infinite;
	animation: oscillate 2s ease infinite;
}

h1,
h2,
h3,
h4,
h5,
h6 {
	font-family: $primary-bold-font-family;
	@media screen and (-webkit-min-device-pixel-ratio: 0) and (min-resolution: 0.001dpcm) {
		font-weight: 500;
	}
	margin: 0;
	padding: 0;
	@media screen and (-webkit-min-device-pixel-ratio: 0) and (min-resolution: 0.001dpcm) {
		font-weight: 500;
	}
	&.outlined {
		color: black;
		-webkit-text-fill-color: white;
		-webkit-text-stroke-width: 2px;
		-webkit-text-stroke-color: black;
	}
	&.highlighted {
		background-color: $secondary-color;
	}
}

p,
li {
	font-family: $primary-font-family;
	font-size: 20px;
	line-height: $standard-line-height;
	margin-bottom: 20px;
}

.fancy-font {
	font-family: $secondary-font-family;
}
.primary-font {
	font-family: $primary-font-family;
}

p,
div,
span {
	&.outlined {
		color: black;
		-webkit-text-fill-color: white;
		-webkit-text-stroke-width: 1px;
		-webkit-text-stroke-color: black;
	}
	&.highlighted {
		background-color: $secondary-color;
	}
	&.small-text {
		font-family: $primary-font-family;
		font-size: 14px;
	}
}

.header-3xl {
	font-size: 70px;
	@media only screen and (min-width: $desktop-width) {
		font-size: 140px;
	}
}

.header-2xl {
	font-size: 70px;
	line-height: 5pc;

	@media only screen and (min-width: $desktop-width) {
		font-size: 100px;
		line-height: 7pc;
	}
}

.header-xl {
	font-size: 60px;
	line-height: 5pc;

	@media only screen and (min-width: $desktop-width) {
		font-size: 70px;
		line-height: 5.5pc;
	}
}

.header-lg {
	font-size: 45px;

	@media only screen and (min-width: $desktop-width) {
		font-size: 50px;
	}
}

.header-md {
	font-size: 30px;
	line-height: 2.5pc;
	margin-bottom: 15px;
	@media only screen and (min-width: $desktop-width) {
		font-size: 35px;
	}
}

.header-sm {
	font-size: 22px;
	@media only screen and (min-width: $desktop-width) {
		font-size: 25px;
	}
}
