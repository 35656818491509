@import 'src/styles/_variables';

.ReactModal__Overlay {
	background-color: rgba(0, 0, 0, 0.75) !important;
	z-index: 1000 !important;
	display: flex;
	justify-content: center;
	align-items: center;
}
.ReactModal__Content {
	border: none !important;
	inset: 0 !important;
	border-radius: 0 !important;
	position: relative !important;
	max-height: 90%;
	overflow-y: auto;
	@media only screen and (min-width: $tablet-width) {
		margin-bottom: 3%;
		max-height: 75%;
	}
	.close {
		position: absolute;
		top: 0px;
		right: 10px;
		font-size: 28px;
		padding: 5px;
		cursor: pointer;
		color: $light-color;
		&:hover {
			color: $primary-color;
		}
	}

	padding: 0 !important;
	.loading {
		height: 50px;
		width: 40px;
		margin: auto;
		.spinner {
			height: 50px;
			width: 50px;
			filter: blur(0px);
		}
		.spinner:before {
			width: 34px;
			height: 34px;
			position: absolute;
			content: '';
			z-index: 10;
			opacity: 10;
			top: 8px;
			left: 8px;
			right: 0px;
			bottom: 0px;
			background-color: $white-color;
			border-radius: 50%;
		}
	}
}
