@import 'src/styles/_variables';

.reauth {
	text-align: center;

	.error {
		position: absolute;
		bottom: 0px;
		left: 0;
		width: 100%;
		padding: 4px 0;
		background-color: $secondary-color;
		font-family: $primary-font-family;
	}
	input {
		border: solid 1px $primary-color;
		height: 25px;
		padding: 2px 5px;
		font-size: 14px;
		margin: 20px;
		text-align: center;
		display: block;
		width: calc(100% - 50px);
	}

	.loading {
		height: 50px;
		width: 40px;
		margin: auto;
	}

	label {
		color: $light-color;
		text-align: left;
		display: block;
		padding-left: 20px;
		margin-bottom: 3px;
		margin-top: 6px;
		font-family: $primary-font-family;
		font-size: 13px;
	}

	.choices {
		display: flex;
	}

	button {
		width: 250px;
		margin-left: auto;
		margin-right: auto;
		margin-top: 10px;
		height: 40px;
		font-weight: bold;
	}

	h5 {
		font-size: 30px;
	}
	p {
		font-size: 15px;
		margin: 5px 0 0;
	}

	.ReactModal__Content {
		display: flex;
		flex-direction: column;
		justify-content: space-between;
		padding: 50px 10px 40px !important;
		overflow-y: hidden !important;
	}

	@media only screen and (min-width: $tablet-width) {
		.ReactModal__Content {
			padding: 50px 30px 40px !important;
		}
	}
}
