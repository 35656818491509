@import 'src/styles/_variables';

.existingDocs {
	.ReactModal__Content {
		display: flex;
		flex-direction: column;
		justify-content: space-between;
		padding-top: 30px !important;
		padding-bottom: 30px !important;
		overflow-y: auto;
		height: 420px;
		width: 100%;
		@media only screen and (min-width: $mobile-width) {
			width: 600px;
		}
	}
	.dividingBorder {
		margin-bottom: 10px;
	}
	#search {
		font-family: $secondary-font-family;
		font-size: 22px;
		border: none;
		outline: none;
		resize: none;
		overflow-y: hidden;
		width: calc(100% - 20px);
	}
	.doc {
		cursor: pointer;
		font-family: $primary-bold-font-family;
		margin-bottom: 5px;
		color: $primary-color;
		text-decoration: none;
		font-size: 18px;
		overflow: hidden;
		text-overflow: ellipsis;
		display: -webkit-box;
		-webkit-line-clamp: 2; /* number of lines to show */
		line-clamp: 2;
		-webkit-box-orient: vertical;
		line-height: 25px;

		p {
			margin: 15px 0 0;
			color: $light-color;
		}
		&:hover {
			background-color: $secondary-color;
		}
	}
}
