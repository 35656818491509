@import 'src/styles/_variables';

footer {
	text-align: center;
	display: flex;
	flex-wrap: wrap;
	width: 100%;
	background-color: $primary-color;
	.getStarted {
		padding-top: 50px;
		padding-bottom: 50px;
		text-align: center;
		width: 100%;
		border-bottom: dotted 1px $white-color;

		h5 {
			color: $white-color;
			margin-bottom: 20px;
			text-align: center;
		}
		@media only screen and (min-width: $desktop-width) {
			width: calc(50% - 1px);
			border-right: dotted 1px $white-color;
			border-bottom: none;
		}
	}
	.links {
		width: 100%;
		text-align: center;
		padding: 15px 0;
		display: grid;
		grid-template-columns: 1fr;
		@media only screen and (min-width: $desktop-width) {
			width: 50%;
			grid-template-columns: 1fr 1fr;
		}
		a {
			color: $white-color;
			display: block;
			text-decoration: none;
			&:hover {
				color: $secondary-color;
			}
			p {
				margin: 10px 0;
			}
		}
	}
}
