@import 'src/styles/_variables';

@-webkit-keyframes slide {
	100% {
		right: 0;
	}
}

@keyframes slide {
	100% {
		right: 0;
	}
}

#examples {
	padding-bottom: 150px;
	min-height: 600px;

	.header {
		margin-bottom: 20px;
		margin-top: 50px;
	}

	hr {
		margin-bottom: 40px;
	}

	a {
		text-decoration: none;
		color: $primary-color;
	}

	.item {
		position: relative;
		cursor: pointer;

		.arrow {
			position: absolute;
			opacity: 0;
			right: 30px;
			bottom: 0px;
			transition: opacity 0.5s, right 0.5s;
			background-color: $white-color;
		}
		&:hover {
			.arrow {
				opacity: 1;
				right: 0;
			}
		}
	}
}
