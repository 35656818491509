@import 'src/styles/_variables';

#humanizer {
	.ProseMirror {
		min-height: 382px;
		overflow-y: auto;
		padding: 8px;
		outline: none;
		p {
			font-size: 16px;
		}
	}

	.ProseMirror p.is-editor-empty:first-child::before {
		color: $light-color;
		content: attr(data-placeholder);
		height: 0;
		pointer-events: none;
		line-height: normal;
	}
}
