@import 'src/styles/_variables';

.controlledBubbleMenu {
	z-index: 1000;
	background-color: $white-color;
	border-radius: 3px;
	width: 400px;
	min-height: 200px;
	max-height: 450px;
	box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.3);
	padding: 10px;
	font-size: 15px;

	.askAIResultDropdownTitle {
		display: block;
		font-size: 18px;
		font-weight: 500;
		position: relative;
		top: 0;
		left: 0;
		width: calc(100% - 10px);
		height: 10%;
		padding: 5px;
		background-color: $secondary-color;
	}
	.askAIResultDropdownOldText {
		margin: 10px 0px;
		color: $light-color;
		min-height: 50px;
	}
	.askAIResultDropdownNewText {
		margin: 10px 0;
		height: 100px;
		overflow: auto;
	}
	.askAIResultDropdownButtonContainer {
		position: relative;
		font-size: 20px;
		width: 100%;
		display: flex;
		flex-direction: row;
		align-items: center;
		justify-content: flex-start;
		margin-top: 10px;
		button {
			font-size: 15px;
		}
	}
}
