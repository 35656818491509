@import 'src/styles/_variables';

.subtopics {
	.subtopicInput {
		width: 100%;
		padding: 12px 10px;
		margin: 10px 0;
		border: 1px dotted $light-color;
		font-size: 1.4rem;
		resize: none;
		outline: none;
		&:focus {
			border: 1px solid $primary-color;
		}
		&.disabled {
			background-color: lighten($light-color, 47%);
		}
	}

	h6 {
		margin-top: 20px;
		text-align: center;
	}
	p {
		margin-top: 10px;
		color: $light-color;
		text-align: center;
	}
}
