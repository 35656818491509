@import 'src/styles/_variables';

.numWords,
.numPages {
	font-family: $primary-font-family;
	margin-right: 15px;
	font-weight: bold;
	.units {
		font-weight: normal;
		text-transform: lowercase;
		margin-left: 1px;
	}
}
.numPages {
	margin-right: 20px;
}
