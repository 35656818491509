@import 'src/styles/_variables';

.customSource {
	.ReactModal__Content {
		display: flex;
		flex-direction: column;
		justify-content: space-between;
		padding: 30px 10px 40px !important;
		min-height: 220px;
		width: 100%;
	}

	@media only screen and (min-width: $tablet-width) {
		.ReactModal__Content {
			padding: 30px 30px 40px !important;
			width: 600px;
			min-height: 220px;
		}
	}
}
