@import 'src/styles/_variables';

.thanks {
	.ReactModal__Content {
		display: flex;
		flex-direction: column;
		justify-content: space-between;
		width: 100%;
	}

	@media only screen and (min-width: $tablet-width) {
		.ReactModal__Content {
			max-width: 550px;
			width: 100%;
		}
	}
}
