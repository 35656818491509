@import 'src/styles/_variables';

.fixed {
	position: fixed;
}

.sticky {
	position: sticky;
	left: 0;
}

.addMoreContainer {
	width: 100%;
	height: 50px;
	bottom: 0;
	background-color: $white-color;
	align-items: center;
	justify-content: flex-start;
	opacity: 0;
	z-index: 1;
	transition: opacity 0.8s;
	&.visible {
		opacity: 1;
	}

	.addMoreBottomContainer {
		display: flex;
		flex-direction: row;
		align-items: center;
		width: 100%;
		margin: 20px 0 15px 0;
	}

	.dropdownHeader {
		font-family: $primary-bold-font-family;
		font-size: 11px;
		margin-top: 0;
		text-transform: uppercase;
		color: $light-color;
	}
	// button {
	// 	font-size: 16px !important;
	// 	padding: 3px 5px;
	// 	margin-top: 0px !important;
	// 	vertical-align: baseline;
	// 	position: relative;
	// 	background-color: $white-color;
	// }
	.slider {
		-webkit-appearance: none;
		width: 100%;
		height: 8px;
		border: dotted 1px $primary-color !important;
		outline: none !important;
		opacity: 0.7;
		-webkit-transition: 0.2s;
		transition: opacity 0.2s;
		border-radius: 5px;
		&:hover {
			background: $secondary-color;
			border-style: solid;
		}
		&::-webkit-slider-thumb {
			-webkit-appearance: none;
			appearance: none;
			width: 20px;
			height: 20px;
			background: $black-color;
			border-radius: 50%;
			cursor: pointer;
		}
	}
	.addMore,
	.revert {
		display: flex;
		align-items: center;
		justify-content: center;
		font-size: 30px;
		cursor: pointer;
		height: 30px;
		width: 30px;
		border: dotted 1px $primary-color;
		border-radius: 50%;
		margin-right: 10px;
		background-color: $white-color;
		&:hover,
		&.open {
			background-color: $secondary-color;
			border-style: solid;
			.MuiSvgIcon-root {
				color: $primary-color;
			}
		}
		.MuiSvgIcon-root {
			color: $light-color;
		}
	}

	.revert {
		.MuiSvgIcon-root {
			width: 0.9em;
			height: 0.9em;
		}
	}

	.spinner {
		height: 30px;
		width: 30px;
	}
	.spinner:before {
		width: 20px;
		height: 20px;
		top: 5px;
		left: 5px;
	}
}

.addMoreSliderValue {
	margin-left: 10px;
	width: 80%;

	.units {
		font-size: 20px;
		color: $light-color;
		margin-left: 7px;
	}
	.value {
		display: inline-block;
	}
}
