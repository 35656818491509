@import 'src/styles/_variables';

.login {
	.ReactModal__Content {
		display: flex;
		flex-direction: column;
		justify-content: space-between;
		padding: 50px 10px 40px !important;
		min-height: 200px;
		width: 90%;
	}

	@media only screen and (min-width: $tablet-width) {
		.ReactModal__Content {
			width: auto;
			min-width: 600px;
			padding: 50px !important;
		}
	}
}
