@import 'src/styles/_variables';

.slider {
	-webkit-appearance: none;
	width: 100%;
	height: 10px;
	border: solid 1px black;
	outline: none;
	-webkit-transition: 0.2s;
	transition: opacity 0.2s;
	border-radius: 5px;
	background-color: transparent;
	&::-webkit-slider-thumb {
		-webkit-appearance: none;
		appearance: none;
		width: 20px;
		height: 20px;
		background: $white-color;
		border: solid 1px black;
		border-radius: 50%;
		cursor: pointer;
	}
}

.sliderValue .value {
	&::-webkit-outer-spin-button,
	&::-webkit-inner-spin-button {
		-webkit-appearance: none;
		margin: 0;
	}
}
