@import 'src/styles/_variables';

.dropdown {
	.addMoreDropdown {
		-webkit-box-shadow: -2px 5px 13px 0px $light-color;
		box-shadow: -2px 5px 13px 0px $light-color;
	}
	.askAIDropdown {
		position: fixed;
		margin-top: 5px;
		left: 0;
		background-color: $white-color;
		border-radius: 3px;
		box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.3);
	}
	.askAIResultDropdown {
		position: relative;
		top: 100px;
		background-color: $white-color;
		border-radius: 3px;
		box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.3);
		width: 400px;
		height: max-content;
		min-height: 200px;
		padding: 10px;
	}
	.triangle {
		position: absolute;
		display: inline-block;
		top: 1px;
		left: 24px;
		color: $white-color;
		z-index: 10;
		width: 27px;
	}
}
