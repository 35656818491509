@import 'src/styles/_variables';

.delete {
	.ReactModal__Content {
		display: flex;
		flex-direction: column;
		justify-content: space-between;
		padding: 50px 10px 40px !important;
	}

	@media only screen and (min-width: $tablet-width) {
		.ReactModal__Content {
			width: 450px;
			padding: 30px 40px !important;
		}
	}
}
